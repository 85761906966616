import { createSlice } from '@reduxjs/toolkit'
import { useTranslation } from 'react-i18next'
import i18n from '../i18n/i18n';

export const preferencesSlice = createSlice({
  name: 'preferences',
  initialState: {
    lang: 'pt',
    timezone: 't39',
    notifications:0
  },
  reducers: {
    switchLang : (state, action) => {
      state.lang = action.payload
      i18n.changeLanguage(action.payload)
    },
    switchTimezone : (state, action) => {
      state.timezone = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { switchLang, switchTimezone } = preferencesSlice.actions

export default preferencesSlice.reducer