import { 
  Box,
  NavLink,
  Divider,
  Space,
  Text
} from '@mantine/core';

import {
  IconContext,
  Gauge,
  Briefcase,
  Certificate,
  Factory,
  Money,
  Eye,
  Question,
  Users,
  ClockCounterClockwise,
  Gear,
  PhoneCall,
  User,
  UsersThree,
  Table,
  BellRinging,
  ShuffleAngular,
  FileDashed,
  Sparkle,
  Crown,
  Headset
} from '@phosphor-icons/react'

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSetState } from '@mantine/hooks';
import { useTranslation } from "react-i18next";

export default function AppMenu({type}) {

  const { t }       = useTranslation();
  const nav         = useNavigate();
  const location    = useLocation();
  const iconContext = {
    color: "#666",
    size: 18,
    weight: "regular",
    mirrored: false
  }

  const menuDefaultState = {
    resources: false,
    billing:   false,
    settings:  false
  }
  const [menuState,setMenuState] = useSetState({...menuDefaultState});
  const toggle = (item) => {
    let itemState = !menuState[item]
    let newState = {...menuDefaultState}
    newState[item] = itemState

    setMenuState(newState)
  }

  return (
    <Box>
      <IconContext.Provider value={iconContext}>
        {type == 'external' && (
          <>
          <NavLink component={Link} to= {import.meta.env.BASE_URL + '/external/'} label={t("menu.Dashboard")} leftSection={<Gauge />} />
          <Divider my="sm" variant="dotted" />
          <NavLink component={Link} to= {import.meta.env.BASE_URL + '/external/contractList'} label={t("menu.Contracts")} leftSection={<Certificate />} />
          <NavLink component={Link} to= {import.meta.env.BASE_URL + '/external/billingList'} label={t("menu.Billing")} leftSection={<Money />} />
          <Divider my="sm" variant="dotted" />
          <NavLink component={Link} to= {import.meta.env.BASE_URL + '/external/userList'} label={t("menu.Users")} leftSection={<Users />} />
          <Divider my="sm" variant="dotted" />
          <NavLink component={Link} to= {import.meta.env.BASE_URL + '/external/help'} label={t("menu.Help")} leftSection={<Question />} />
          </>
        )}

        {type == 'service' && (
          <>
          <NavLink component={Link} to= {import.meta.env.BASE_URL + '/service/'} label={t("menu.Dashboard")} leftSection={<Gauge />} />
          <Divider my="sm" variant="dotted" />
          <NavLink component={Link} to= {import.meta.env.BASE_URL + '/service/contactList'} label={t("menu.Contacts")} leftSection={<Users />} />
          <NavLink component={Link} to= {import.meta.env.BASE_URL + '/service/resourceList'} label={t("menu.Locations")} leftSection={<Factory />} />
          <NavLink component={Link} to= {import.meta.env.BASE_URL + '/service/readingList'} label={t("menu.Readings")} leftSection={<ClockCounterClockwise />} />
          <Divider my="sm" variant="dotted" />
          <NavLink component={Link} to= {import.meta.env.BASE_URL + '/service/help'} label={t("menu.Help")} leftSection={<Question />} />
          </>
        )}

        {(type == 'admin' || type == 'operator') && (
          <>
          <NavLink component={Link} to= {import.meta.env.BASE_URL + '/admin'} label={t("menu.Dashboard")} leftSection={<Gauge />} />
          <NavLink component={Link} to= {import.meta.env.BASE_URL + '/admin/contactList'} label={t("menu.Contacts")} leftSection={<PhoneCall />} />
          <Space h="md" />
          <NavLink component={Link} to= {import.meta.env.BASE_URL + '/admin/leadList'} label={t("menu.Leads")} leftSection={<Crown />} />
          <NavLink component={Link} to= {import.meta.env.BASE_URL + '/admin/clientList'} label={t("menu.Clients")} leftSection={<Briefcase />} />
          <NavLink component={Link} to= {import.meta.env.BASE_URL + '/admin/contractList'} label={t("menu.Contracts")} leftSection={<Certificate />} />
          <Space h="md" />
          
          <NavLink opened={menuState.resources} onClick={() => toggle('resources')} component={Link} to="#" label={t("menu.Resources")} leftSection={<Factory />} childrenOffset={0}>
            <NavLink component={Link} to= {import.meta.env.BASE_URL + '/admin/resourceDashboard'} label={t("menu.Overview")} />
            <NavLink component={Link} to= {import.meta.env.BASE_URL + '/admin/buildingList'} label={t("menu.Buildings")} />
            <NavLink component={Link} to= {import.meta.env.BASE_URL + '/admin/roomList'} label={t("menu.Rooms")} />
            <NavLink component={Link} to= {import.meta.env.BASE_URL + '/admin/meterList'} label={t("menu.Meters")} />
            <Space mb="xl" />
          </NavLink>

          <NavLink opened={menuState.billing} onClick={() => toggle('billing')} label={t("menu.Billing")} leftSection={<Money />} childrenOffset={0}>
            <NavLink component={Link} to= {import.meta.env.BASE_URL + '/admin/readingList'} label={t("menu.Readings")} />
            <NavLink component={Link} to= {import.meta.env.BASE_URL + '/admin/billingList'} label={t("menu.Billing documents")} />
            <NavLink component={Link} to= {import.meta.env.BASE_URL + '/admin/pricingList'} label={t("menu.Pricing")} />
          </NavLink>

          {(type=='admin') && (
            <>
              <Space h="md" />
              <NavLink component={Link} to= {import.meta.env.BASE_URL + '/admin/auditList'} label={t("menu.Audit")} leftSection={<Eye />} />

              <NavLink opened={menuState.settings} onClick={() => toggle('settings')} component={Link} to="#" label={t("menu.Settings")} leftSection={<Gear />} childrenOffset={0}>
                {/* <NavLink component={Link} to="/admin/tables" label={t("menu.Reference )}tables" /> */}
                <Space my="sm" />
                <NavLink component={Link} to= {import.meta.env.BASE_URL + '/admin/workflowList'} label={t("menu.Workflows")} />
                <Space my="sm" />
                <NavLink component={Link} to= {import.meta.env.BASE_URL + '/admin/messageTypeList'} label={t("menu.Notification models")} />
                <NavLink component={Link} to= {import.meta.env.BASE_URL + '/admin/messageGroupList'} label={t("menu.Notification groups")} />
                {/* <NavLink component={Link} to= {import.meta.env.BASE_URL + '/admin/templateList'} label={t("menu.Templates")} /> */}
                <Space my="sm" />
                <NavLink component={Link} to= {import.meta.env.BASE_URL + '/admin/userList'} label={t("menu.Users")} />
                <NavLink component={Link} to= {import.meta.env.BASE_URL + '/admin/userGroupList'} label={t("menu.Profiles")} />
              </NavLink>
              <Space h="md" />
              <NavLink component={Link} to= {import.meta.env.BASE_URL + '/admin/ticketList'} label={t("menu.Support tickets")} leftSection={<Headset />} />

            </>
          )}

          <NavLink component={Link} to= {import.meta.env.BASE_URL + '/admin/help'} label={t("menu.Help")} leftSection={<Question />} />
          </>
        )}
      </IconContext.Provider>
    </Box>
  );
}