import { DataTable } from 'mantine-datatable';
import { useTranslation } from 'react-i18next'
import { Avatar, Text } from '@mantine/core';
import { NoRecordsIcon } from '@/components/DataTableNoRecordsIcon';
import { Empty } from './Empty';

export const NotificationsTable = function ({records}) {

  const { t } = useTranslation()

  if (records.length == 0) {
    return <Empty />
  }
  
  return (
    <DataTable
      highlightOnHover
      horizontalSpacing="xl"
      style={{ background: 'transparent' }}
      records={records}
      recordsPerPage={5}
      noRecordsText={t('global.No records found')}
      noRecordsIcon={<NoRecordsIcon />}
      columns={[
        {
          accessor: 'subject',
          title: t('Subject'),
          sortable: true,
        },
        {
          accessor: 'date_sent',
          title: t('Date'),
        },
      ]}
    />
  )
}