import { ActionIcon, Group, useMantineTheme, Modal, Indicator, Text, Box, Drawer, Burger, Affix, Image, ScrollArea, Divider, UnstyledButton, Button, LoadingOverlay } from '@mantine/core';
import { useDisclosure, useSetState } from '@mantine/hooks';
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import AppMenu from '../components/AppMenu'
import UserButton from '../components/UserButton'
import Logo from '../assets/logo.svg'
import LogoU from '../assets/logoU.svg'
import { Asterisk, Bell, IconContext, MagnifyingGlass } from '@phosphor-icons/react';
import { SearchModal } from '../components/SearchModal'
import { UtilsModal } from '../components/UtilsModal'
import { NotificationsModal } from '../components/NotificationsModal'
import { useEffect } from 'react';

import { useHotkeys } from '@mantine/hooks';

import { useNotificationListUserQuery } from '@/services/api.js';


export default function AdminLayout({type}) {
  const theme    = useMantineTheme();
  const nav      = useNavigate();
  const location = useLocation();

  useHotkeys([
    ['mod+K', () => setState({searchModal:true})],
  ]);
  
  useEffect(() => {
    // console.log('location', location.key); // { key: "3uu089" }
    // Fire whatever function you need.
    // sessionStorage.setItem('whatever', state);
    close()

  }, [location.key]);

  const iconContext = {
    color: theme.colors.gray[0],
    size: 16,
    weight: "regular",
    mirrored: false
  }

  const [state, setState] = useSetState({
    searchModal : false,
    utilsModal : false,
    tempModal:false,
  })

  const [opened, {open, close, toggle}] = useDisclosure(false);

  const { data = [], error, isLoading, isFetching, refetch } = useNotificationListUserQuery();
  if (isLoading) return <LoadingOverlay visible />
  
  return (
    <>

      <Modal size={700} opened={state.searchModal} onClose={() => setState({searchModal:false})} title="Search">
        <SearchModal />
      </Modal>

      <Modal opened={state.utilsModal} onClose={() => setState({utilsModal:false})} title="Utilities">
        <UtilsModal />
      </Modal>

      <Modal size={700} opened={state.notificationsModal} onClose={() => setState({notificationsModal:false})} title="Notifications">
        <NotificationsModal />
      </Modal>

      <div className="window">

          <Box className="navigationHolder">
            <div className="navigation">

              <div className="logo">
                <img src={Logo} />
                <Group position="apart">
                  <Text fz="xs" c="gray.6">UPSYS</Text>
                </Group>
                <IconContext.Provider value={iconContext}>
                  <Group mt="lg">
                    <ActionIcon onClick={() => setState({searchModal:true})} variant="filled" color="gray.7" size="md" radius="xl"><MagnifyingGlass /></ActionIcon>
                    {(data?.payload.total>0) && (
                      <Indicator inline label={data?.payload.total} size={18} color="red">
                        <ActionIcon onClick={() => setState({notificationsModal:true})} variant="filled" color="gray.7" size="md" radius="xl"><Bell /></ActionIcon>
                      </Indicator>
                    )}
                    {(data?.payload.total == 0) && (
                      <ActionIcon onClick={() => setState({notificationsModal:true})} variant="filled" color="gray.7" size="md" radius="xl"><Bell /></ActionIcon>
                    )}
                    <ActionIcon onClick={() => setState({utilsModal:true})} variant="filled" color="gray.7" size="md" radius="xl"><Asterisk /></ActionIcon>
                  </Group>
                </IconContext.Provider>
              </div>

              <div className="mainMenu">
                <div>
                  <AppMenu type={type} />
                </div>
              </div>
              <div className="userMenu">
                <UserButton />
              </div>

            </div>
          </Box>
          <div className="content">
            <Outlet />
          </div>
      </div>

      <Box style={{position:'fixed',left:0,width:'100vw',top:0, zIndex:199,background:'#110'}} hiddenFrom="md">
        <Group justify="space-between" px="xl" py="xs">
          <Group>
            <UnstyledButton onClick={() => nav('/frontend/admin')}><Image width={50} src={LogoU} /></UnstyledButton>
            <Divider orientation="vertical" />
            <Text c={'#fff'}>Clientes</Text>
          </Group>
          <Burger color="#fff" onClick={toggle} />
        </Group>
      </Box>

      <Drawer opened={opened} onClose={close} size="sm" hiddenFrom="md" title={<img src={LogoU} style={{marginLeft:'1rem',filter:'invert(100)'}} />}>
        <AppMenu type={type} />
      </Drawer>
    </>
  );
}