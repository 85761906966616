import { Container, Center, Text, Title } from '@mantine/core';
import { EyeSlash } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';

export function Empty ({title='No records found', text='Please try searching with a different criteria'}) {

  const { t } = useTranslation();

  return (
    <>
      <Container mt={100}>
        <Center><EyeSlash size={72} color="#eef" /></Center>
        <Center mt="lg"><Title order={3} c="dimmed">{t(title)}</Title></Center>
        <Center mt="lg"><Text>{t(text)}</Text></Center>
      </Container>
    </>
  )
}
