import { LoadingOverlay, Tabs, Title } from "@mantine/core"
import { useTranslation } from "react-i18next"
import { NotificationsTable } from "./NotificationsTable"
import { useNotificationListUserQuery } from '@/services/api.js';
import { useState } from "react";

export const NotificationsModal = function () {

  const { t } = useTranslation()
  const [criteria, setCriteria] = useState({entity_group_id:'client'});
  const [page, setPage]         = useState(1);
  const [query, setQuery]       = useState({});
  const [date,setDate]          = useState(Date.now());
  const { data = [], error, isLoading, isFetching, refetch } = useNotificationListUserQuery({criteria:criteria,page:page,ts:date});

  if (isLoading) return <LoadingOverlay visible />
  if (error) return <Error />

  return (
    <>
      <NotificationsTable
        highlight    = {data?.payload.highlight}
        records      = {data?.payload.list}
        recordsPerPage={data?.payload.recordsPerPage}
        totalRecords = {data?.payload.total}
        page         = {data?.payload.page}
        setPage      = {setPage}
        sort         = {data?.payload.sort}
        // sortAction   = {sortAction}
        // rowAction    = {rowAction}
        fetching     = {isFetching}
      />
    </>
  )
}
